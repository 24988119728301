import config from './config.js';
import axios from "axios";
export default{
    getWx(callback){
    	  var url="jsapi-json.php?type=url&url="+encodeURIComponent(location.href)
	      axios.get(url)
	      .then(response => {
        	callback(response.data);
	      })
    }
}