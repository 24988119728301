<template>
	  	<div ref="navBarList" class="nav-bar-list">
		  	<div class="nav-bar-list-pc">
		          <div class="nav-list NormalSmSize" to="/about"  @click='gotoAbout'>
		          	<p>
		          NEKU<br>
		      		</p>
		      </div>
		        <div class="nav-list NormalSmSize" to="/product" @click='gotoProduct'>
		        	<p>
		            Product<br>
		          </p>

		        </div>
		         <div class="nav-list NormalSmSize" to="/contact" @click='gotoContact'>
		           <p>
		           Contact<br>
		           </p>
		        </div>
		        <div class="nav-lang NormalSmSize">
		        	<p>
		        		<br>
		           	<a href="http://nekupet.com" style="color:#ccc;font-weight: bold; font-size: 13px">漢字</a> / En<br>
		           </p>
	        	</div>
	        </div>
	        
      </div>
</template>

<script>
	import pconfig from '../http/config';

	export default{
		name:'nav-bar-list',
		data(){
			return{
				show:false,
			}
		},
		props:{
			navshow:Boolean,
		},
		created(){
			this.show=this.navshow;
		},
		watch:{
			navshow(){
				this.show=this.navshow;
				this.navUpDown(this.show);
			}
		},
		methods:{
			gotoAbout(e){
				// console.log(e)
				this.$emit('about');
			},
			gotoProduct(e){
				this.$emit('product');

			},
			gotoContact(e){
				this.$emit('contact');

			},
			navUpDown(b){
				if(b){
					// console.log(this.$refs.navBarList);
					TweenMax.to(this.$refs.navBarList,pconfig.TweenTime,{'top':'55px','opacity':1})
				}else{
					// console.log(b);
					TweenMax.to(this.$refs.navBarList,pconfig.TweenTime,{'top':'-400px','opacity':1})

				}
			}

		}
	}
</script>


<style lang="scss" scoped>
	@media (min-width: 850px){
	  .nav-bar-list{
	  		position: absolute;
	  		background: #3e3a39;
	  		z-index:0;
			width: 100%;
			top: -300px;
			opacity: 1;
			.nav-bar-list-pc{
			  	max-width: 1600px;
			    height: 60px;
				position: relative;

			    .nav-list{
				  	display: inline-block;
				    color: #fff;
				    width:20%;
				    height: 100%;
				    cursor:pointer;
				    position: relative;
				 }

				 .nav-list p{
				    color: #fff;
					top:50%;
					left: 50%;
					position: absolute;
					transform: translate(-50%,-50%);
				}
				 .nav-lang{
				 	display: inline-block;
				 	position: absolute;
				 	float: right;
				  	width: 10%;
				  	height: 100%;
				  	right: 0;
					p{
					color: #fff;

					}
				  }
		  }
		 
	  }
	}
	@media (max-width: 850px){
	  .nav-bar-list{
	  		position: absolute;
	  		background: #3e3a39;
	  		z-index:0;
			width: 100%;
			top: -400px;
			opacity: 1;
			.nav-bar-list-pc{
			    .nav-list{
				  	display: inline-block;
				    width:79%;
				    height: 100px;
				    cursor:pointer;
				    border-bottom: 1px solid #fff;
				    position: relative;

				}
				.nav-list p{
				    color: #fff;
					top:50%;
					left: 50%;
					position: absolute;
					transform: translate(-50%,-50%);
				}

				.nav-list:last-child{
					border-bottom: none;
				}
				.nav-lang{
					width:79%;
					display: inline-block;
					height: 70px;
					p{
					color:#fff;

					}
				}
		  }
	  }
	}
</style>