<template>
	<div id='nav-icon-box'>
		<div id="nav-icon" @click='openEvent' :class="openControl?'open':''">
			<span></span>
			<span></span>
			<span></span>
		</div>
	</div>
	
</template>

<script>
	export default{
		name:'nav-icon',
		data(){
			return{
				openControl:false
			}
		},
		methods:{
			openEvent(e){
				let open=this.openControl;
				open=!open;
				this.openControl=open;
        if(open){
          this.$emit("OPEN_NAV")
        }else{
          this.$emit("CLOSE_NAV")
        }
			}
		}
	}
</script>

<style lang="scss" scoped>
	/* Icon */

#nav-icon-box{
	position: absolute;
	right: 2%;
	top:50%;
	transform: translate(-50%,-50%);
}

#nav-icon{
  width: 21px;
  height: 30px;
  position: relative;
  margin: 0 auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span{
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #9f9d9c;
  border-radius: 0px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 10%;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(2) {
  top: 40%;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon span:nth-child(3) {
  top: 70%;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 20%;
  left:3px;
}

#nav-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top:70%;
  left: 3px;
}
</style>