<template>
    <div class="catmatProductoBanner">
       <swiper id="bannerBox" v-bind:options="swiperOption" ref="introToiletSwiper">
        <swiper-slide class="swiper-slide" v-for="(item, index) in imglists" :key="index">
          <div class="banner">
            <img :src="item" alt="">
          </div>
        </swiper-slide>
       <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

      </div>
</template>

<script>
  import config from '../http/config';
  import mbtn from './morebtn'
  import cbtn from './cbtn'

export default {
  name: 'catmatProductoBanner',
  data() {
    return {
      imglists:[
          'images/s-catmatIntro-1.png',
          'images/s-catmatIntro-2.png',
          'images/s-catmatIntro-3.png',
      ],
      swiperOption: {
        notNextTick: true,
        direction: 'horizontal',
       
        pagination: {
          el: '.swiper-pagination',
          bulletActiveClass:'swiper-pagination-acc2'
        },
        on:{
          
        }
      },
    }
  },
  created(){
  },
  computed: {
   
  },
  mounted(){

  },
  methods:{

  },
  components: {

  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>


@media(max-width: 850px){
    .catmatProductoBanner{
      width: 8.2666666667rem;
      height: 7.1466666667rem;
      background: #f8f8f9;
      color:#3e3a39;
      border-radius: 10px;
      
    }
     #bannerBox{
      display: block;
      height: 100%;
      height: 100%;
     border-radius: 10px;

    }
    
    .swiper-pagination{
       bottom: 4%;
    }
    .swiper-slide{
      width: 100%;
      height: 100%;
        border-radius: 10px;

      .banner{
        height: 100%;
        width: 100%;
        border-radius: 10px;

      }
      img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
}
</style>
