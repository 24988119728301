<template>
  <div class="home" id='home'>

    <Swiper id="componentsBox" :options="swiperOptions" ref="mySwiper">
    	<swiper-slide class='swiper-slide'><banner></banner></swiper-slide>

    	<swiper-slide class='swiper-slide'><intro></intro></swiper-slide>

      <swiper-slide class='swiper-slide'><catmat></catmat></swiper-slide>

      <swiper-slide class='swiper-slide'><catsnacks></catsnacks></swiper-slide>

    	<swiper-slide class='swiper-slide'><cattoilet></cattoilet></swiper-slide>

    	<swiper-slide class='swiper-slide'><shoveltool></shoveltool></swiper-slide>
    
      <swiper-slide class='swiper-slide'><catlitter></catlitter></swiper-slide>

      <swiper-slide class='swiper-slide'><deodorizing></deodorizing></swiper-slide>

      <swiper-slide class='swiper-slide'><pagefooter></pagefooter></swiper-slide>
    </Swiper>

    <div id='componentsBoxMoblie' ref='componentsBoxMoblie' v-scroll="handleScroll">
        <div><banner ></banner></div>
        <div id='moblieAbout'><intro></intro></div>
        <div id='moblieProduct'><catmat @SHOW_INTRO_MOBILE='showIntro'></catmat></div>
        <div><catsnacks @SHOW_INTRO_MOBILE='showIntro'></catsnacks></div>
        <div><cattoilet @SHOW_INTRO_MOBILE='showIntro'></cattoilet></div>
        <div><shoveltool @SHOW_INTRO_MOBILE='showIntro'></shoveltool></div>
        <div ><catlitter @SHOW_INTRO_MOBILE='showIntro' ></catlitter></div>
        <div><deodorizing></deodorizing></div>
        <div id='moblieContact'><pagefooter ></pagefooter></div>
    </div>

    <transition name="slide-fade">
    <div class='pop' v-if='showPop'>
      <div class="popbg"></div>

      <div class="catlitter-pop popbox" v-if='(popShowId===1)'>
          <div class="info">
              <img src="images/p3intro.png" alt="">
          </div>
          <cbtn class='closebtn' @click.native='closePop'></cbtn>
      </div>

      <div class="cattoilet-pop popbox" v-if='(popShowId===2)'>
        <div class="info">
              <!-- <img src="images/p4intro.png" alt=""> -->
              <toiletProductroBanner></toiletProductroBanner>
          </div>
          <cbtn class='closebtn' @click.native='closePop'></cbtn>
      </div>

      <div class="shoveltool-pop popbox" v-if='(popShowId===3)'>
        <div class="info">
              <img src="images/p5intro-m.png" alt="">
          </div>
          <cbtn class='closebtn' @click.native='closePop'></cbtn>
      </div>

      <div class="catsnacks-pop popbox" v-if='(popShowId===4)'>
        <div class="info">
              <!-- <img src="images/p7intro-m.png" alt=""> -->
              <snacksProductoBanner></snacksProductoBanner>
          </div>
          <cbtn class='closebtn' @click.native='closePop'></cbtn>
      </div>

      <div class="catsnacks-pop popbox" v-if='(popShowId===5)'>
        <div class="info">
              <!-- <img src="images/p7intro-m.png" alt=""> -->
              <catmatProductoBanner></catmatProductoBanner>
          </div>
          <cbtn class='closebtn' @click.native='closePop'></cbtn>
      </div>
    </div>
  </transition>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import banner from '../components/topbanner'
import intro from '../components/intro'
import catsnacks from '../components/catsnacks'
import catlitter from '../components/catlitter'
import cattoilet from '../components/cattoilet'
import shoveltool from '../components/shoveltool'
import deodorizing from '../components/deodorizing'
import pagefooter from '../components/pagefooter'
import cbtn from '../components/cbtn'
import toiletProductroBanner from '../components/toiletProductroBanner'
import snacksProductoBanner from '../components/snacksProductoBanner'
import catmatProductoBanner from '../components/catmatProductoBanner'
import catmat from '../components/catmat'

import pconfig from '../http/config';

export default {
  name: 'home',
  data() {
    return {
      list: [], //轮换列表
      showPop:false,
      popShowId:1,
      swiperOptions: {
        notNextTick: true,
        direction: 'vertical',
        grabCursor: false,
        setWrapperSize: true,
        autoHeight: true,
        slidesPerView: 1,
        mousewheel: true,
        mousewheelControl: true,
        height: window.innerHeight-55, 
        resistanceRatio: 0,
        observeParents: true,
        on:{
          slideChange: ()=>{
            let swiper = this.$refs.mySwiper.swiper;
            // console.log(swiper.activeIndex); //滑动打印当前索引

          }
        }
      }
    }
  },
  created(){
    // this.list = [0,1,2,3,4,5,6,7,8,9];
    this.$root.eventHub.$on('SHOW_ABOUT',(data)=>{
      if(this.$refs.mySwiper){let swiper = this.$refs.mySwiper.swiper; swiper.slideTo(1);}
      this.goAnchor('#moblieAbout');
    });
    this.$root.eventHub.$on('SHOW_PRODUCT',(data)=>{
      // console.log('SHOW_PRODUCT')
      if(this.$refs.mySwiper){ let swiper = this.$refs.mySwiper.swiper; swiper.slideTo(2);}
      this.goAnchor('#moblieProduct');

    })
    this.$root.eventHub.$on('SHOW_CONTACT',(data)=>{
      // console.log('SHOW_CONTACT')
      if(this.$refs.mySwiper) {let swiper = this.$refs.mySwiper.swiper;swiper.slideTo(8);}
      this.goAnchor('#moblieContact');
    })
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  mounted(){
    // this.swiper.slideTo(3, 1000, false); //手动跳到指定页
    
  },
  methods:{
      closePop(e){
          this.showPop=!this.showPop;
          this.popShowId=0;
      },
      showIntro(data){
          this.showPop=!this.showPop;
          this.popShowId=data;
      },
      goAnchor(val) {
        let anchor = this.$el.querySelector(val);
        this.$scrollTo(val, pconfig.tweenTime, { easing: 'ease', cancelable: false })
    },
    handleScroll(evt, el){
        var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
      }
  },
  components: {
    Swiper,
    banner,
    intro,
    catlitter,
    cattoilet,
    shoveltool,
    deodorizing,
    pagefooter,
    cbtn,
    catsnacks,
    toiletProductroBanner,
    snacksProductoBanner,
    catmatProductoBanner,
    catmat
  },
  watch:{
      '$route' (parem){
        console.info(parem);
       }
  }
}
</script>

<style scoped lang="scss">
.home{
	width: 100%;
  background: #f3f3f4;

}
@media (max-width: 850px){
	#componentsBox{
		display: none;
	}
	#componentsBoxMoblie{
		display: block;
  	}
}
@media (min-width: 850px){
	#componentsBox{
		display: block;
    height: 100%;
    overflow: hidden;
	}
	#componentsBox-moblie{
		display: none;
	}
}
.swiper-slide{
  font-size: 24px;
  text-align: center;
  line-height: 100px;
  overflow: hidden;
}
.swiper-slide:nth-child(2n){
  background: #fff;
}
.swiper-slide:nth-child(2n-1){
  background: #fff;
}
.pop{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}
.popbg{
  background: rgba(0,0,0, 0.7);
  width: 100%;
  height: 100%;
}
.closebtn{
   right: 10px;
   top:10px;
   background: #3e3a39 !important;
   z-index: 2;
}
.popbox{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
.catlitter-pop{

  .info{
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 60px;
      padding-bottom: 60px;
      background: #fff;
      border-radius: 10px;
  }
}
.cattoilet-pop{
 
  .info{

      background: #fff;
      border-radius: 10px;
    
  }

}

.shoveltool-pop{
  .info{
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 40px;
      padding-bottom: 40px;
      background: #fff;
      border-radius: 10px;
     img{
        width: 5.6133333333rem; 
      }
  }
}

.catsnacks-pop{
  .info{
      // padding-left: 20px;
      // padding-right: 20px;
      // padding-top: 40px;
      // padding-bottom: 40px;
      background: #fff;
      border-radius: 10px;
     img{
        width: 6.6666666667rem; 
      }
  }
}
</style>
