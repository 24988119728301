import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      props: { href: '' }
    },
    {
    	path:'/404',
    	name:'404',
    	component:Home
    },
    {
      path: '*',  
      redirect: '/404'
    }
  ]
})
