<template>
  <div class="cattoilet">
      <div class="cattoilet-box">

        <div class="product-box">
            <div class="product-bottom">
              <!-- <img src="images/p4pro2.png" alt=""> -->
            </div>
            <div class="product-top">
              <img src="images/p4pro1.png" alt="">
            </div>
        </div>
        <mbtn class='mbtnpos' :title="mtitle" v-if='!showIntroB' @click.native="IntroShowCon()"></mbtn>
        <div class="page-title">
            <img src="images/p4title.png" alt="">
        </div>
        <transition name="slide-fade">
        <div class="spec-img" v-if='!showIntroB'>
            <img src="images/p4mm.png" alt="">
        </div>
      </transition>
        <transition name="slide-fade">
         <div class="select-img" v-if='showBigPic'>
             <div v-for='(item,index) in plistBigdata' :key='index' v-show='index===selectID' class="selectbigimg">
                <img :src="item" alt="">
              </div>
          </div>
        </transition>
          <transition name="slide-fade">
        <div class="introImgbox" v-if='!showIntroB'>
            <div class="intro-select-box">
                <div v-for='(item,index) in plistdata'  :key='index'  class="selectimg" id='index' @mouseover='smImgMoveOver(index)' @mouseleave='smImgMoveOut'>
                  <img :src="item" alt=""  >
                </div>
            </div>
        </div>
      </transition>
        <transition name="slide-fade">
          <div class="introbox" v-if='showIntroB'>
            <div class="intro-img" v-if='!showBigPic' :style="{ backgroundImage: 'url(' + introimg + ') ' }">
                <cbtn class="closebtn" @click.native="IntroShowCon()"></cbtn>
            </div>
          </div>
      </transition>
      </div>
      <div class="cattoilet-mobile">

        <div class="product-box">

          <div class="page-title">
            SOLVE 6<br>
            QUESTIONS
        </div>
        <div class="spec-img" >
            <img src="images/p4mm-m.png" alt="">
        </div>

        <div class="mbtn-box">
            <mbtn class='mbtnpos' :title="mtitle" @click.native="IntroShowConMobile()"></mbtn>
        </div>


            <div class="product-bottom">
              <!-- <img src="images/p4pro2.png" alt=""> -->
            </div>
            <div class="product-top">
              <img src="images/p4pro1.png" alt="">
            </div>
        </div>

        

      </div>  
  </div>
</template>

<script>
  import config from '../http/config';
  import mbtn from './morebtn'
  import cbtn from './cbtn'

export default {
  name: 'cattoilet',
  data() {
    return {
        mtitle:'MORE',
        plistdata:[
          'images/p4smpicA.png',
          'images/p4smpicB.png',
          'images/p4smpicC.png',
          'images/p4smpicD.png',
        ],
        plistBigdata:[
          'images/p4biga.png',
          'images/p4bigb.png',
          'images/p4bigc.png',
          'images/p4bigd.png',
        ],
        introimg:'images/p4intro.png',
        showIntroB:false,
        showBigPic:false,
        selectID:null,
    }
  },
  created(){
  },
  computed: {
   
  },
  mounted(){

  },
  methods:{
     IntroShowCon(){
          this.showIntroB=!this.showIntroB;      
      },
      IntroShowConMobile(){
        this.$emit('SHOW_INTRO_MOBILE',2)

      },
      smImgMoveOver(id){
        this.showBigPic=true;
        this.selectID=id;
      },
      smImgMoveOut(){
        this.showBigPic=false;
        this.selectID=null;
      }
  },
  components: {
    mbtn,cbtn
  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>
@media(min-width: 850px){
  
  .cattoilet{
      width: 100%;
      height: 100%;
      background: #fdfdfd;
      color:#3e3a39;
      .cattoilet-box{
            display: block;

            @media (max-width: 1440px) {
               width: 90%;
               height: 90%;
               
            }
            @media (min-width: 1440px) {
               width: 100%;
               height: 100%;
               min-width: 1024px;
               max-width: 1400px;
               min-height: 700px;
               max-height: 800px;
            }
            left:50%;
            top:50%;
            position: absolute;
            transform: translate(-50%,-50%);
      }
      .cattoilet-mobile{
        display: none;
      }
      .mbtnpos{
        bottom: 24px;
        left: 0;
      }
      .page-title{
        position: absolute;
        left:0;top:0;
        width: 208px;
        height: 73px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .spec-img{
        position: absolute;
        bottom: 80px;
        width: 193px;
        height:136px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .product-box{
        position: absolute;
        right:-5%;
        bottom:-85px;
        width: 756px;
        height: 756px;
        .product-bottom{
          position: absolute;
          width: 779px; height: 680px;
          top:410px;
        }
        .product-top{
          position: absolute;
          @media (max-width: 1440px) {
              width: 591px; height: 475px;
              right:20px; top:150px;
              img{
                width: 100%;
              }
          }
          @media (min-width: 1440px) {
              width: 591px; height: 475px;
              left:66px;

          }
        }
      }
      .select-img{
          position: absolute;
          @media (max-width: 1440px) {
              width: 456*0.8px;
              img{width: 100%}
          }
          @media (min-width: 1440px) {
                width: 456px;
                height: 100%;
          }
          top:-6px;left: 223px;
          background-position: left center;
          background-repeat: no-repeat;
         
        }
      .introImgbox{
        position: absolute;
        bottom: 0;left: 348px;
        width: 244px;
        height: 244px;
      .intro-select-box{
          width: 100%;
          height: 100%;
          .selectimg{
              width: 94px;
              height: 94px;
              float: left;
              margin:10px 10px;
              cursor: pointer;
          } 
        }
        
      }
      .introbox{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 670px;
        height: 333px;

        .intro-img{
          width: 100%;
          height: 100%;
          position: absolute;
          top:0;left: 0;
          background-position: left center;
          background-repeat: no-repeat;
          background-size: contain;
        }

      }
      .closebtn{
        left: 650px;
      }
  }
}
@media(max-width: 850px){
 .cattoilet{
      width: 100%;
      height: 17.12rem;
      background: #fdfdfd;
      color:#3e3a39;
      .cattoilet-box{
        display: none;
      }
      .cattoilet-mobile{
        display: block;
      }
      .mbtn-box{
        margin-top: 0.6rem ;
        height: 40px;
        position: relative;
         .mbtnpos{
            top: 50%;
            left: 4.0866666667rem;
          }
      }
      .page-title{
        padding-top: 1.2rem;
        width: 75%;
        height: 0.9466666667rem;
        padding-bottom: 0.8rem;
        border-bottom:1px solid #d5d4d4;
        font-size: .48rem;
          font-weight:bold;

      }
      .spec-img{
        width: 100%;
        height:2.1866666667rem;
        padding-top:0.8133333333rem;
        img{
          width:2.5733333333rem;
          height: 100%;
        }
      }
      .product-box{
        position: relative;
        width: 100%;
        height: 9.12rem;
        .product-top{
          position: absolute;
          width:10.3866666667rem;
          height: 9.0666666667rem;
          top:9.0333333333rem;
          left:0.1rem;
          img{
            width: 85%; height: 85%;
          }
        }
        .product-bottom{
          position: absolute;
          width: 6.9466666667rem; height: 5.5733333333rem;
          left:0.9rem;
          top:13.6933333333rem;
           img{
            width: 100%; height: 100%;
          }
        }
        


      }
    }
}
</style>
