<template>
  <div class="catmat">
      <div class="catmat-box">

        <div class="product-box">
          <div class="product-bottom">
              <img src="images/p8pro.png" alt="">
          </div>
        </div>

        <transition name="slide-fade" >
          <div class="page-title pc-title-ma" v-if='!showBigPic'>
              <!-- <img src="images/p7title.png" alt=""> -->
            HOKKAIDO </br>
           TOAST CAT MAT
          </div>
      </transition>

        <div class="spec-img" v-if='!showIntroB'>
            <!-- <img src="images/p7spec.png" alt=""> -->
            Can adapt to different stylesof home environment
        </div>

        <transition name="slide-fade">
        <div class="select-img" v-if='showBigPic'>
             <div v-for='(item,index) in plistBigdata' v-show='index===selectID' class="selectbigimg">
                <img :src="item" alt="">
              </div>
          </div>
        </transition>

        <transition name="slide-fade">
        <div class="introImgbox" v-if='!showIntroB'>
            <div class="intro-select-box">
                <div class="p8logo"><img src="images/p8logo.png" alt="logo"></div>
                <div v-for='(item,index) in plistdata' class="selectimg" id='index' @mouseover='smImgMoveOver(index)' @mouseleave='smImgMoveOut'>
                  <img :src="item" alt=""  >
                </div>
            </div>
        </div>

      </transition>
        <transition name="slide-fade">
          <div class="introbox" v-if='showIntroB'>
            <div class="intro-img" v-if='!showBigPic' :style="{ backgroundImage: 'url(' + introimg + ') ' }">
                <cbtn class="closebtn" @click.native="IntroShowCon()"></cbtn>
            </div>
          </div>
      </transition>

      </div>
      <div class="catmat-mobile">
        <div class="product-box">
          <div class="page-title">
            HOKKAIDO </br>
           TOAST CAT MAT
          </div>
        <div class="spec-img" >
             Can adapt to different styles </br>
              of home environment
        </div>
       <div class="mbtn-box">
            <mbtn class='mbtnpos' :title="mtitle" @click.native="IntroShowConMobile()"></mbtn>
        </div>
        <div class="product-bottom">
          <img src="images/p8pro.png" alt="">
        </div>
        <div class="p8logo">
          <img src="images/p8logo.png" alt="">
        </div>
        </div>

        

      </div>  
  </div>
</template>

<script>
  import config from '../http/config';
  import mbtn from './morebtn'
  import cbtn from './cbtn'

export default {
  name: 'catmat',
  data() {
    return {
        mtitle:'MORE',
        plistdata:[
          'images/p8smpicA.png',
          'images/p8smpicB.png',
          'images/p8smpicC.png',
        ],
        plistBigdata:[
          'images/p8biga.png',
          'images/p8bigb.png',
          'images/p8bigc.png',
        ],
        introimg:'images/p7intro.png',
        showIntroB:false,
        showBigPic:false,
        selectID:null,
    }
  },
  created(){
  },
  computed: {
   
  },
  mounted(){

  },
  methods:{
     IntroShowCon(){
          this.showIntroB=!this.showIntroB;      
      },
      IntroShowConMobile(){
        this.$emit('SHOW_INTRO_MOBILE',5)

      },
      smImgMoveOver(id){
        this.showBigPic=true;
        this.selectID=id;
      },
      smImgMoveOut(){
        this.showBigPic=false;
        this.selectID=null;
      }
  },
  components: {
    mbtn,cbtn
  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>
@media(min-width: 850px){
  
  .catmat{
      width: 100%;
      height: 100%;
      background: #fbfbfb;
      color:#3e3a39;
      .catmat-box{
        @media (max-width: 1440px) {
           display: block;
           width: 90%;
           height: 90%;
           
        }
        @media (min-width: 1440px) {
           display: block;
           width: 100%;
           height: 100%;
           min-width: 1024px;
           max-width: 1400px;
           min-height: 700px;
           max-height: 800px;
          
        }
       left:50%;
       top:50%;
       position: absolute;
       transform: translate(-50%,-50%);
      }
      .catmat-mobile{
        display: none;
      }
      .mbtnpos{
        bottom: 24px;
        left: 0;
      }
      .page-title{
        position: absolute;
        right:0;top:0;
        width:394px;
        height: 71px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .pc-title-ma{
         text-align: right;
         line-height: 36px;
         font-size: 36px;
         font-weight:bold;
         color:#3e3a39;
      }
      .spec-img{
        position: absolute;
        bottom: 110px;
        height:107px;
        font-size: 22px;
        right: 0px;
        font-family: 'pingFangSC-Regular';
      }
      .select-img{
          width: 465px;
          height: 100%;
          position: absolute;
          top:0;right:0;
          background-position: left center;
          background-repeat: no-repeat;
      }
      .product-box{
        position: absolute;
        width: 600px;
        height: 100%;
        .product-bottom{
          position: absolute;
          width: 100%;
          @media (max-width: 1440px) {
              width: 90%;
          }
          top:56%;
          left: 50%;
          transform: translate(-50%,-50%);
          img{
            width: 100%;
          }
        }
      }
      .introImgbox{
        position: absolute;
        bottom: 0;right: 0;
        // width: 244px;
        height: 105px;
      .intro-select-box{
          width: 100%;
          height: 100%;
          .selectimg{
              float: left;
              width: 105px;
              height: 105px;
              float: left;
              margin:8px;
              cursor: pointer;
          } 
            .p8logo{
              float: left;
              margin-top: 24px;
              margin-right: 12px;
              width: 147px; height: 105px;
            }
        }
        
      }
      .introbox{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 498px;
        height: 339px;
        .intro-img{
          width: 100%;
          height: 100%;
          position: absolute;
          top:0;left: 0;
          background-position: left center;
          background-repeat: no-repeat;
          background-size: contain;
        }

      }
      .closebtn{
        right: 498px;
      }

  }
}
@media(max-width: 850px){
 .catmat{
      width: 100%;
      height: 14.8666666667rem;
      background: #fbfbfb;
      color:#3e3a39;
      .catmat-box{
        display: none;
      }
      .catmat-mobile{
        display: block;
      }
      .mbtn-box{
        height: 0.5133333333rem;
        position: relative;
         .mbtnpos{
            // top: 50%;
            left: 4.0866666667rem;
          }
      }
      .page-title{
        padding-top: 1.2rem;
        width: 75%;
        height: 0.9466666667rem;
        padding-bottom: 0.8rem;
        font-size: .48rem;
          font-weight:bold;
      }
      .spec-img{
        width: 6.52rem;
        height: 1.4266666667rem;
        font-size: 0.2933333333rem;
        img{
          width:100%;
          height: 100%;
        }
      }
      .product-bottom{
        position: relative;
        width:6.0933333333rem;
        height: 7.28rem;
        margin-top: 0.7066666667rem;
         img{
          width: 100%; height: 100%;
        }
      }
      .p8logo{
          position: relative;
          width:1.96rem; height: 0.9466666667rem;
          img{
            width: 100%; height: 100%;
          }
        }
      }
}
</style>
