<template>
  <div class="shoveltool">
      <div class="shoveltool-box">
        <div class="product-box">
            <div class="product-bottom">
              <img src="images/ptool.png" alt="">
            </div>
        </div>
        <mbtn class='mbtnpos' :title="mtitle" v-if='!showIntroB' @click.native="IntroShowCon()"></mbtn>
        <div class="page-title">
            <img src="images/p5title.png" alt="">
        </div>
        <div class="spec-img" v-if='!showIntroB'>
            <img src="images/p5spec.png" alt="">
        </div>
        <transition name="slide-fade">
          <div class="introbox" v-if='showIntroB'>
            <div class="intro-img" :style="{ backgroundImage: 'url(' + introimg + ') ' }">
                <cbtn class="closebtn" @click.native="IntroShowCon()"></cbtn>
            </div>
          </div>
      </transition>
      </div>

    <div class="shoveltool-mobile">

         <div class="page-title">
          ERGONOMIC CAT<br>
          LITTER SCOOP
        </div>

        <div class="spec-img" >
            <img src="images/p5spec-m.png" alt="">
        </div>

        <div class="mbtn-box">
          <mbtn :title="mtitle" class='mbtnpos'  @click.native="IntroShowConMobile()" ></mbtn>
        </div>

        <div class="product-box">
            <div class="product-bottom">
              <img src="images/ptool.png" alt="">
            </div>
        </div>


      </div>
  </div>
</template>

<script>
  import config from '../http/config';
  import mbtn from './morebtn'
  import cbtn from './cbtn'

export default {
  name: 'shoveltool',
  data() {
    return {
        mtitle:'MORE',
        introimg:'images/p5intro.png',
        showIntroB:false,
    }
  },
  created(){
  },
  computed: {
   
  },
  mounted(){

  },
  methods:{
     IntroShowCon(){
          this.showIntroB=!this.showIntroB;      
      },
      IntroShowConMobile(){
        this.$emit('SHOW_INTRO_MOBILE',3)

      }
  },
  components: {
    mbtn,cbtn
  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>
@media(min-width: 850px){
  .shoveltool{
      width: 100%;
      height: 100%;
      background: #f8f8f9;
      color:#3e3a39;
      .shoveltool-box{
        @media (max-width: 1440px) {
            width: 90%;
           height: 90%;
           
        }
        @media (min-width: 1440px){
          width: 100%;
           height: 100%;
           min-width: 1024px;
           max-width: 1400px;
           min-height: 700px;
           max-height: 800px;
        }
           
           left:50%;
           top:50%;
           position: absolute;
           transform: translate(-50%,-50%);
      }
      .shoveltool-mobile{
        display: none;
      }
      .mbtnpos{
        bottom: 0;
        right: 0;
      }
      .page-title{
        position: absolute;
        right:0;top:0;
        width: 307px;
        height: 71px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .spec-img{
        position: absolute;
        right: 0;
        bottom: 55px;
        width: 193px;
        height: 136px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .product-box{
        position: absolute;
        left:-5%;
        bottom:0%;
        width: 756px;
        height: 756px;
        .product-bottom{
          position: absolute;
          width: 871px; height: 828px;
          @media (max-width: 1440px) {
              top:60px;
              width: 871*0.9px; height: 828*0.9px;
              img{width:100%;}
          }
        }
      }
      .introbox{
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 473px;
        height: 182px;

        .intro-img{
          width: 473px;
          height: 100%;
          position: absolute;
          top:0;right: 0px;
          background-position: left bottom;
          background-repeat: no-repeat;
        }
        
      }
      .closebtn{
        right: -20px;
      }
  }
}

@media(max-width: 850px){
    .shoveltool{
      width: 100%;
      height:auto;
      background: #f8f8f9;
      color:#3e3a39;
       .shoveltool-box{
        display: none;
       }
        .shoveltool-mobile{
        display: block;
        width: 100%;
       }

       .mbtn-box{
        margin-top: 0.6rem ;
        height: 40px;
        position: relative;
         .mbtnpos{
            top: 50%;
            left: 4.0866666667rem;
          }
      }
      
       .page-title{
            padding-top: 1.2rem;
            width: 75%;
            height: 0.9466666667rem;
            padding-bottom: 0.8rem;
            border-bottom:1px solid #d5d4d4;
           font-size: .48rem;
          font-weight:bold;
           
       }

       .spec-img{
        height:2.1866666667rem;
        width: 75%;
        padding-top: 0.8rem;
          img{
              height: 2.1866666667rem;
          }
       }
       .product-box{
           width: 100%;
           margin-top: 0;
          img{
            width: 100%;
            transform:scale(-1, 1);
          }
       }
    }
}
</style>
