<template>
  <div class="cbtn">
     <div class="b-title NormalSmSize-bold">{{btitle}}</div>
     <div id='cb1'></div>
     <div id='cb2'></div>
  </div>
</template>

<script>
  import config from '../http/config'
export default {
  name: 'cbtn',
  data() {
    return {
    }
  },
  props:{
    title:String
  },
  created(){
      this.btitle=this.title;
  },
  computed: {
   
  },
  mounted(){

  },
  components: {
    
  },
  watch:{
      title(){
        this.btitle=this.title;
      }
  }
}
</script>

<style lang="scss" scoped>
  .cbtn{
      width: 20px;
      height: 20px;
      border-radius: 10px;
      position: absolute;
      cursor: pointer;
      background: #de5858;
      #cb1{
        height: 2px;
        width: 50%;
        background: #fff;
        top:45%;
        left: 25%;
        // transform: translate(-50%,-50%);
        transform:rotate(45deg);
        position: absolute;
      }

      #cb2{
        height: 2px;
        width: 50%;
        background: #fff;
        top:45%;
        left: 25%;
        // transform: translate(-50%,-50%);
        transform:rotate(135deg);
        position: absolute;
      }
  }
  .cbtn:hover,
      :focus {
        background: #de5855
      };
</style>
