<template>
  <div class="intro">
      <div class="intro-box">
        
        <div class="intro-list">
          <div class="lists">
            <h1>Practical philosophy</h1>
            <br>
            <br>

           Neku is a brand jointly developed by team from Chinese, Korean and Japanese companies to enter the global market with the pet products and pet food. The companies are jointly participated in various fields to provide a premium quality products base on each improvement and upgrade.
           <br/><br/>
Working with Neku, Enjoying the Premium Product, Experiencing the Professional Service.


</div>


        </div>
      </div>

  </div>
</template>

<script>
export default {
  name: 'intro',
  data() {
    return {
      
    }
  },
  created(){
  },
  computed: {
   
  },
  mounted(){

  },
  components: {
  
  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>
  
@media (min-width: 850px) {
      .intro{
      position: relative;
      background: #f3f3f4;
      height: 100%;
      width: 100%;
        .intro-box{
          width:100%;
          max-width: 1400px;
          min-width: 1024px;
          height: 513px;
          margin: auto;
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);
          .intro-tt{
             width:100%;
             height: 171px;
             display: block;
             float: left;
             font-size: 36px;
             line-height: 171px;
             // background: url('../assets/part2title.png') no-repeat center top;
          }
          .intro-list{
             width: 70%;
             height: 100%;
             display: block;

             .lists{
               font-family: 'pingFangSC-Regular';
              width: 100%;
              padding-bottom: 50px;
              font-size: 16px;
              margin-bottom:50px;
              margin-left:20px;
              margin-right:20px;
              border-bottom: 1px solid #ccc;
              white-space:normal;
              line-height: 30px;
              text-align: left;
              float: left;
             }
              .lists:last-child{
                border-bottom: none;
               }
              .lists h1{
                text-transform:uppercase;
                font-weight: normal;

              }
              
          }
        }
      }
  }
@media (max-width: 850px) {
    .intro{
      position: relative;
      background: #f3f3f4;
      height: 10.96rem;
      width: 100%;
        .intro-box{
          width: 100%;
          height: 100%;
          .intro-tt{
             width:100%;
             height: 1.96rem;
             line-height: 1.96rem;
             font-size: 0.48rem;
          }
          .intro-list{
              width: 80%;
              position: absolute;
              top: 50%; left: 50%;
              transform: translate(-50%, -50%);
             .lists{
                font-size:0.32rem;
                white-space:normal;
                line-height: 0.5rem;
                text-align:justify;
                display: table-cell;
                padding-top: 0.6666666667rem;
                padding-bottom: 0.6666666667rem;
                border-bottom: 1px solid #ccc;
            }
            .lists h1{
              font-size: .48rem;
              text-transform:uppercase;
            }
            .lists:last-child{
                border-bottom: none;
              margin-bottom: 2rem;
                
            }
            .lists:nth-child(1){
                padding-top: 0;
            }

      }
       
    }
  }
}
</style>
